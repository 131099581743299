import { defineStore } from "pinia";
import _ from "lodash";

const defaultState = {
    shapeType: null,
    shapeComplete: false,
    wallSizeEditorActive: false,
    wallSizeEditorValue: "0",
    wallInterfacePosition: {},
    // freeWallActive: false,
    // controls: {
    //     deleteWall: false
    // },
    previousData: {},
    storingRoomData: false,
};

export const useDrawingStore = defineStore('drawing', {
    state: () => ({...defaultState}),
    actions: {
        completeShape() {
            this.shapeComplete = true;
        },
        updateShapeType(shapeType) {
            this.shapeType = shapeType;
        },
        resetWallSizeEditor() {
            this.wallSizeEditorValue = "0";
            this.wallSizeEditorActive = false;
        },
        reset(keys = null) {
            Object.assign(this, keys?.length
                ? _.pick(defaultState, keys)
                : defaultState // if no keys provided, reset all
            );
        },
    }
});
