import * as PIXI from 'pixi.js';
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import RotationControl from '@/utils/classes/RotationControl';
import { createNewIcon } from '@/utils/pixiFloorplan/Marker';
import type { ConfigurationMarker } from '@/types/configurationmarkers.types';
import type { ProductVariant } from '@/types/productvariant.types';
import type { Wall } from '@/types/room.types';
import type { PixiMarker } from '@/types/piximarker.types';

export default class DrawRegularGraphics {
    public markerGraphics: PixiMarker;
    private configurationMarker: ConfigurationMarker;
    private product_variant: ProductVariant;
    private container: PIXI.Container;
    private wall: Wall;

   constructor(options: {
        configurationMarker: ConfigurationMarker,
        product_variant: ProductVariant,
        container: PIXI.Container,
        wall: Wall,
    }) {
        this.configurationMarker = options.configurationMarker;
        this.product_variant = options.product_variant;
        this.container = options.container;
        this.wall = options.wall;

        // return this.drawRegularGraphics(this.configurationMarker, this.product_variant);
        this.markerGraphics = this.drawRegularGraphics(this.configurationMarker, this.product_variant);

        if(!this.configurationMarker.marker.wall_mounted){
            new RotationControl(this, this.container)
        }

        this.container.addChild(this.markerGraphics);
    }

    private drawRegularGraphics(configurationMarker: ConfigurationMarker, product_variant: ProductVariant): Graphics {
        const markerGraphics = new PIXI.Graphics() as PixiMarker;
        const isRadiator = configurationMarker.marker.reference.includes('radiator');

        let coordinates = configurationMarker.position_data;
        let markerSvg = configurationMarker.marker.icon;

        let graphicLength = product_variant?.length || 60;
        let graphicWidth = product_variant?.width || 100;

        let makerCornerRadius = 10;

        if(isRadiator){
            graphicLength = 30;
            graphicWidth = 70;
        }

        markerGraphics.lineStyle(2, 0xdbdbdb);
        markerGraphics.beginFill('0xffffff');

        //Draw a rectangle starting from the center
        markerGraphics.drawRoundedRect(-graphicWidth / 2, -graphicLength / 2, graphicWidth, graphicLength, makerCornerRadius);
        markerGraphics.endFill();

        markerGraphics.position.set(coordinates.x, coordinates.y);

        //Pass the pivotOffset to the marker so we can deduct it again to send to the visualizer
        markerGraphics.pivotOffsetX = 0;
        markerGraphics.pivotOffsetY = 0;

        markerGraphics.wall = this.wall;
        markerGraphics.radiator = isRadiator;

        markerGraphics.zIndex = 100;

        //PixiJS Deprecation Warning: utils.hex2rgb is deprecated, use Color#toRgbArray insteadDeprecated since v7.2.0
        //PixiJS Deprecation Warning: settings.FILTER_RESOLUTION is deprecated, use Filter.defaultResolutionDeprecated since v7.1.0
        //Waiting for a filter update so these warnings goes away
        const dropShadowFilter = new DropShadowFilter();
        dropShadowFilter.alpha = 0.1;
        dropShadowFilter.blur = 5;
        dropShadowFilter.offset.set(0, 0);

        markerGraphics.filters = [dropShadowFilter];

        markerGraphics.xid = configurationMarker.xid;

        markerGraphics.angle = configurationMarker.position_data.rotation ?? 0;
        markerGraphics.savedRotation = 0;
        markerGraphics.graphicLength = graphicLength;
        markerGraphics.graphicWidth = graphicWidth;

        this.container.addChild(markerGraphics);

        addIcon(markerGraphics, markerSvg, configurationMarker.marker.reference);

        return markerGraphics;
    }

    updateRotation(newRotation: number) {
        this.markerGraphics.savedRotation = newRotation;
    }
}

/**
 * Adds an icon to a graphic using the provided image and reference.
 *
 * @param {PIXI.Container} graphic - The graphic container to add the icon to.
 * @param {string} image - The URL or path of the icon image.
 * @param {string} reference - The reference ID for the icon in the cache.
 * @return {void}
 */
export function addIcon(graphic, image, reference){
    if(!image){
        return;
    }

    const graphicRotation =  graphic.angle ? -graphic.angle : 0;

    if(PIXI.Assets.cache.has(image)){
        createNewIcon(graphic, PIXI.Assets.cache.get(image), 24, graphicRotation);
    }else{
        PIXI.Assets.add({
            alias: reference,
            src: image,
            data: {  crossOrigin: true }
        });

        PIXI.Assets.load(image).then((texture) => {
            if (!texture || typeof texture === 'string') {
                throw new Error('Failed to load texture');
            }

            createNewIcon(graphic, texture, 24, graphicRotation);
        }).catch((error) => {
            console.error(`Error loading icon: ${error.message}`);
        });
    }
}