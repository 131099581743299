import { defineStore } from "pinia";
// import { formatDate } from '@/utils/dates';
import type { Client } from "@/types/client.types";
import { useMarkerStore } from "./marker";
import _ from 'lodash-es';

interface State {
    client: Client;
    state: string;
    currency: "EUR" | "USD";
    cutoff: number;
    use_configuration_webhook: boolean;
    environment: string;
    debugMode: boolean;
    vat_settings: ["inc_new", "inc_renovation"];
    active_vat_setting: "inc_new" | "inc_renovation";
    sunSettings: {
        time_of_day: string,
        north_offset: number;
    },
    currentRoute: string;
    routeParams: object;
}

export const useGeneralStore = defineStore('general', {
    state: (): State => {
        return {
            client: null,
            state: 'start',
            currency: 'EUR',
            cutoff: 2, //percentage how many extra tiles needed (move this to client level and do calculations in the backend, pass second parameter square_meters with cutoff)
            use_configuration_webhook: false,
            environment: __APP_ENV__,
            debugMode: false,
            vat_settings: ["inc_new", "inc_renovation"],
            active_vat_setting: 'inc_new',
            sunSettings: {
                time_of_day: 'day',
                north_offset: 0,
            },
            currentRoute: null,
            routeParams: null,
        }
    },
    getters: {
        isRetail: (state: State): boolean => state.client && state.client.type == 'retail',
        currencySymbol: (state: State): "€" | "$" => {
            switch (state.currency) {
                case 'EUR':
                    return '€';
                case 'USD':
                    return '$';
                default:
                    return '€';
            }
        },
        cutoffPercentage: (state: State): number => (state.cutoff / 100) + 1,
        showSkuNumbers: (state: State): boolean => state.environment !== 'production' || state.debugMode,
        vatAmount: (state: State): 21 | 6 => state.active_vat_setting === 'inc_new' ? 21 : 6,
        sunStance(state: State): {
            date_time: string,
            north_offset: number
        } {
            const date_time = {
                day: '13:00',
                dusk: '18:00',
                night: '00:00',
            }[this.sunSettings.time_of_day] || '13:00';

            return {
                date_time,
                north_offset: state.sunSettings.north_offset,
            }
        }
    },
    actions: {
        toggleDebugMode(): void {
            this.debugMode = !this.debugMode;
        },
        showOverview(category: string): void{
            //show correct state when category is overview
            if(category === 'overview'){
                this.state = 'summary';
            }else{
                this.state = '';
            }
        },
        setCurrency(currency: State['currency']): void {
            this.currency = currency;
        },
        setClient(client: Client): void {
            this.client = client;
            this.use_configuration_webhook = client?.use_configuration_webhook;
        },
        switchVatSetting(setting: State['vat_settings'] | null = null): void {
            if(setting){
                this.active_vat_setting = setting;
                return
            }

            const currentIndex = this.vat_settings.findIndex(setting => setting === this.active_vat_setting);
            const nextIndex = (currentIndex + 1) % this.vat_settings.length;
            this.active_vat_setting = this.vat_settings[nextIndex];
        },
        setCurrentRoute(pageUrl) {

            if (this.currentRoute == route().current() && _.isEqual(this.routeParams, route().params)) {
                return;
            }

            const markerStore = useMarkerStore();

            this.currentRoute = route().current();
            this.routeParams = route().params;

            if (![
                'client.configurations.modules.module',
                'client.configurations.modules.product-group'
            ].includes(this.currentRoute)) {
                return markerStore.activeConfigurationMarkerXid = null;
            }

            markerStore.activeConfigurationMarkerXid = this.routeParams.module;
        }
    }
});
