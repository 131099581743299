<script lang="ts">
import IconLoader from "../icons/IconLoader.vue";
import { CircleArrowRight, MoveLeft, FolderOpen, Pencil, RotateCcw, Search, ArrowUpDown, ArrowDownWideNarrow, ArrowUpNarrowWide, Filter, Move3D, Check, FileDown } from 'lucide-vue-next';

export default {
    components: { IconLoader, CircleArrowRight, MoveLeft, FolderOpen, Pencil, RotateCcw, Search, ArrowUpDown, ArrowDownWideNarrow, ArrowUpNarrowWide, Filter, Move3D, Check, FileDown },
    props: {
        type: {
            type: String,
            default: 'secondary',
            validator: value => [
                'primary',
                'secondary',
                'light',
                'white',
                'link',
                'success',
                'outline',
                'drawer-primary',
                'drawer-secondary'
            ].includes(value),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        icon: String,
        iconBefore: String,
        iconOutline: {
            type: Boolean,
            default: true,
        },
        overrideDisabledClass: String,
        fullWidth: {
            type: Boolean,
            default: false,
        },
        function: {
            type: String,
            default: 'button',
            validator: (value: string) => ['button', 'submit', 'reset'].includes(value),
        }
    },
    data: function () {
        return {
            buttonClasses: {
                primary: 'bg-primary-600 rounded-full text-white',
                'drawer-primary': 'bg-white rounded-2xl text-primary-600',
                'drawer-secondary': 'bg-white/25 rounded-2xl text-white',
                secondary: 'bg-primary-gray-700 rounded-full text-white',
                light: 'bg-primary-gray-200 rounded-full !shadow-none',
                white: 'bg-white rounded-full text-primary-600',
                success: 'bg-green-400 rounded-lg !shadow-none text-white',
                outline: 'border border-primary-300 text-primary-300 rounded-full !shadow-none',
                link: 'border-none border border-primary-300 text-primary-300 text-base',
            },
            enabledClasses: {
                primary: 'hover:bg-gradient-to-r hover:from-primary-600 hover:to-primary-500',
                secondary: 'hover:bg-primary-500',
                light: 'hover:bg-primary-gray-300',
                white: 'hover:bg-white/80',
                success: 'hover:bg-green-600',
                outline: 'hover:bg-gray-50',
                link: 'hover:text-slate-600',
            },
            disabledClasses: {
                primary: 'opacity-50',
                secondary: 'opacity-50',
            },
        };
    },
    computed: {
        buttonClass() {
            return this.buttonClasses[this.type];
        },
        enabledClass() {
            return this.enabledClasses[this.type];
        },
        disabledClass() {
            if (this.overrideDisabledClass) {
                return this.overrideDisabledClass;
            }

            return this.disabledClasses[this.type];
        },
        isDisabled() {
            return this.disabled || this.loading;
        },
    },
}
</script>

<template>
    <button :class="[buttonClass,
                    isDisabled ? 'cursor-not-allowed ' + disabledClass : 'cursor-pointer ' + enabledClass,
                    type !== 'link' ? 'shadow-md h-12 px-6' : '',
                    fullWidth ? 'w-full justify-center' : '',
                ]"
            :disabled="isDisabled"
            role="button"
            :type="$props.function"
            class="ease-in-out transition flex flex-none items-center font-medium leading-none space-x-3"
        >
        <template v-if="iconBefore">
            <div>
                <component
                    v-if="!loading && iconBefore"
                    :is="iconBefore"
                    class="w-5 h-5 -mr-1 stroke-current"
                ></component>
                <IconLoader v-else class="w-5 h-5 -mx-1"></IconLoader>
            </div>
        </template>

        <div v-if="$slots.default" class="flex-grow">
            <div class="flex items-center justify-center mx-1 font-normal">
                <slot></slot>
            </div>
        </div>

        <template v-if="icon">
            <div>
                <component
                    v-if="!loading && icon"
                    :is="icon"
                    class="w-5 h-5 -mx-1 stroke-current"
                ></component>
                <IconLoader v-if="loading" class="w-5 h-5 -mx-1"></IconLoader>
            </div>
        </template>
    </button>
</template>
