import { collisionSystem, debugCollision } from "@/types/collision.types";
import { Container, Graphics, Assets, Sprite } from "pixi.js";
import { useMarkerStore } from '@/stores';
import { createNewIcon, setNewIcon } from '@/utils/pixiFloorplan/Marker';
import * as visualizerAction from "@/utils/visualizer";
import { snapToAngle } from "@/utils/objectUtils";
import { PixiMarker } from "@/types/piximarker.types";

export default class RotationControl {
    private circle: Graphics;
    private dot: Graphics;
    private dragging: boolean = false;
    private radius: number = 30;
    private graphicClass: any;
    private graphicMarker: PixiMarker;
    private container: Container;

    constructor(
        graphicMarker: any,
        container: Container
        ) {

        this.graphicClass = graphicMarker;
        this.graphicMarker = graphicMarker.markerGraphics;
        this.container = container;

        this.circle = new Graphics();
        this.circle.lineStyle(2, 0x4294F5);
        this.circle.arc(0, 0, this.radius, 0, Math.PI * 2);
        this.circle.visible = false;
        this.circle.zIndex = 120;
        this.circle.rotationConfigurationId = this.graphicMarker.xid;

        this.dot = new Graphics();
        this.dot.beginFill(0x4294F5);
        this.dot.drawCircle(0, 0, 10);
        this.dot.endFill();
        this.dot.position.set(this.radius, 0);

        this.dot.eventMode = 'static';
        this.dot.zIndex = 120;

        this.addDotIcon();

        this.dot.on('pointerdown', this.onDragDotStart.bind(this))
                 .on('pointerup', this.onDragDotEnd.bind(this))
                 .on('pointerupoutside', this.onDragDotEnd.bind(this))
                 .on('globalpointermove', this.onDragDotMove.bind(this));

        this.container.addChild(this.circle);
        this.circle.addChild(this.dot);

        //When moving the parent graphic hide the rotation
        this.graphicMarker.on('pointerdown', this.hideRotation.bind(this));

        //When releasing the move update the position
        this.graphicMarker.on('pointerup', this.updatePosition.bind(this))
                        .on('pointerupoutside', this.updatePosition.bind(this));
    }

    private hideRotation() {
        this.circle.visible = false;
    }

    private updatePosition() {
        //Manually update dot position when releasing the click on the graphic
        const markerAngleRadians = (Math.PI / 180) * this.graphicMarker.angle;
        this.dot.position.set(this.radius * Math.cos(markerAngleRadians), this.radius * Math.sin(markerAngleRadians));

        //Show the circle and update position
        this.circle.visible = true;
        this.circle.x = this.graphicMarker.x;
        this.circle.y = this.graphicMarker.y;
    }

    private onDragDotStart(event) {
        this.dot.dragData = event.data;
        this.dragging = true;
    }

    private onDragDotEnd() {
        this.dragging = false;
    }

    private onDragDotMove(event) {
        if (this.dragging) {
            const localPos = this.circle.toLocal(event.data.global);
            const angle = Math.atan2(localPos.y, localPos.x);

            //Snap the rotation close to each 90 degree angle
            const snappedAngle = snapToAngle(angle);

            this.dot.position.set(this.radius * Math.cos(snappedAngle), this.radius * Math.sin(snappedAngle));
            this.updateRotationFromDot();
        }
    }

    private updateRotationFromDot() {
        const angleRadians = Math.atan2(this.dot.y, this.dot.x);
        const angleDegrees = angleRadians * (180 / Math.PI);

        //Rotate the marker in collisionSystem
        const markerBody = collisionSystem.all().find(body => body.xid === this.graphicMarker.xid)
        markerBody.setAngle(angleRadians, true)

        //Rotate the marker in pixi
        this.graphicMarker.rotation = angleRadians;

        //Save the marker rotation (degrees)
        this.graphicClass.updateRotation(angleDegrees);

        //Save rotation in postion_data
        const markerStore = useMarkerStore();
        markerStore.changeMarkerRotation(angleDegrees);

        setNewIcon(this.graphicMarker, this.graphicClass.configurationMarker.marker.icon, this.graphicClass.configurationMarker.marker.reference);

        const markerPosition = {
            x: this.graphicMarker.x,
            y: -this.graphicMarker.y,
            rotation: this.graphicMarker.savedRotation,
        };
        visualizerAction.moveObject(this.graphicMarker.xid, markerPosition);

        debugCollision.update()
    }

    private addDotIcon(){
        const image = '/images/icons/arrow-clockwise.png'

        if(Assets.cache.has(image)){
            createNewIcon(this.dot, Assets.cache.get(image), 12, -90);
        }else{
            Assets.add({
                alias: 'arrow-clockwise',
                src: image,
                data: {  crossOrigin: true }
            });
    
            Assets.load(image).then((texture) => {
                if (!texture || typeof texture === 'string') {
                    throw new Error('Failed to load texture');
                }
    
                createNewIcon(this.dot, texture, 12, -90);
            }).catch((error) => {
                console.error(`Error loading icon: ${error.message}`);
            });
        }
    }
}
