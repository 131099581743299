import { Configuration } from "@/types/configuration.types";
import { defineStore } from "pinia";
import axios from 'axios';
import { useGeneralStore } from "./general";
import { useMarkerStore } from "./marker";
import { useRoomStore } from "./rooms";

export const useConfigurationStore = defineStore('configuration', {
    state: () => {
        return {
            configuration: null as Configuration | null,
            savingConfiguration: false,
        }
    },
    actions: {
        $reset () {
            this.configuration = null;
        },
        setConfiguration(configuration: Configuration) {
            this.configuration = configuration;
        },
        async saveConfiguration (storeAsNew = false, additionalData = {}) {
            const generalStore = useGeneralStore();
            const markerStore = useMarkerStore();

            this.savingConfiguration = true;

            try {

                const endpoint = storeAsNew
                    ? route('client.configurations.store')
                    : route('client.configurations.patch', {
                        configuration: this.configuration.id,
                    });

                const { floorplan_data, room_height, square_meters } = useRoomStore().room
                const response = await axios[storeAsNew ? 'post' : 'patch'](endpoint, {
                    room: { floorplan_data, room_height, square_meters },
                    room_type: 'bathroom',
                    vat: generalStore.vatAmount.toString(),
                    configurationMarkers: markerStore.configurationMarkers,
                    ...additionalData,
                });

                this.savingConfiguration = false;

                return response.data;
            } catch (error) {
                this.createNotification({
                    type: 'error',
                    message: error.message,
                });

                this.savingConfiguration = false;

                throw error;
            }
        },
    }
});
