import { computed } from 'vue';
import { Configurable } from '@/types/Configurable.types';
import { ProductGroup } from '@/types/productgroups.types';

export class Marker {

	id: number;
    name: string;
    reference: string;
    drawing_logic: string;
    marker_positioning: string;
    marker_category: string;
    icon: string;
    physical_marker_id: number | null;
    marker_group_id: number | null;
    marker_group: object;
    wall_mounted: boolean;
    wall_height: number | null;
    encasing: boolean;
    carve: boolean;
    sellable: boolean;
    primary_product_group_id: number | null;
    configurable: boolean;
    configurables: Configurable[];

	constructor(markerData) {
		this.id = markerData.id || null;
		this.name = markerData.name || '';
		this.reference = markerData.reference || '';
		this.drawing_logic = markerData.drawing_logic || '';
		this.marker_positioning = markerData.marker_positioning || '';
		this.marker_category = markerData.marker_category || '';
		this.icon = markerData.icon || '';
		this.physical_marker_id = markerData.physical_marker_id || null;
		this.marker_group_id = markerData.marker_group_id || null;
		this.marker_group = markerData.marker_group || '';
		this.wall_mounted = markerData.wall_mounted || 0;
		this.wall_height = markerData.wall_height || null;
		this.encasing = markerData.encasing || 0;
		this.carve = markerData.carve || 0;
		this.sellable = markerData.sellable ||  false;
		this.primary_product_group_id = markerData.primary_product_group_id || null;
		this.configurable = markerData.configurable || false;
		this.configurables = markerData.configurables || [];
	}

	get productGroups (): ProductGroup[] {
		return computed(() => {
			if (!this.configurables) {
				return [];
			}

			return this.getProductGroups((this.configurables as any));
		}) as any;
	}

	get primaryProductGroup (): ProductGroup {
		return computed(() => {
			return this.getFirstChildRecursive(this.id, 'marker').subject;
		}) as any;
	}

	getFirstChildRecursive (parentId: Number, parentType: String) {
		const firstChild = this.getFirstChild(parentId, parentType);

		if (firstChild.subject_type === 'product_group') {
			return firstChild;
		}

		return this.getFirstChildRecursive(firstChild.id, firstChild.subject_type);
	}

	getFirstChild (parentId: Number, parentType: String) {
		return this.getChildren(parentId, parentType)
			.sort((a, b) => a.sort_order - b.sort_order)[0];
	}

	getChildren (parentId: Number, parentType: String) {
		return this.configurables.filter((configurable) => {
			return configurable.parent_id == parentId && configurable.parent_type == parentType;
		});
	}

	getProductGroups (configurables: Configurable[]) {
		return configurables
			.sort((a, b) => a.sort_order - b.sort_order)
			.reduce((groups, configurable) => {
				if (configurable.subject_type === 'product_group') {
					groups.push(configurable.subject);
				} else {
					groups = groups.concat(this.getProductGroups(configurable.subject.configurables));
				}

				return groups;
			}, []);
	}
}
